import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,  RouterProvider } from "react-router-dom";
import { datadogLogs } from '@datadog/browser-logs';
import './index.css';
import App from './App';
import Home from './Home';
import Test from './Test';
import ErrorPage from './Error';

datadogLogs.init({
  clientToken: 'pub5c4a554b0024b4c29bbb8047fb97d4c1',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: 'Restaurant Default',
  env: 'uat',
  version: '0.0.1',
  organisation: 'TYIMXJyrsby3hAf4Z1Wk',
  storeId: 'fm-generic',
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/test",
    element: <Test />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
