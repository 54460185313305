import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { datadogLogs } from '@datadog/browser-logs';

const App = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const serial = searchParams.get('serial');
    if (serial) {
      datadogLogs.setGlobalContext({serial: serial})
      datadogLogs.setUser({ id: serial, name: 'Restaurant Default', organisation: 'TYIMXJyrsby3hAf4Z1Wk' })
    }
    
  }, [searchParams])

  return (
    <>
      <h1>{`Serial: ${searchParams.get('serial')}`}</h1>
      <div>
        <ul>
          <li><Link to={`home`}>Home Page</Link></li>
          <li><Link to={`test`}>Test Serial</Link></li>
        </ul>
      </div>
    </>
  );
}

export default App;
