import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { format } from 'date-fns';
import { datadogLogs } from '@datadog/browser-logs';

export default function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      datadogLogs.logger.error('Something is wrong!', { 
        name: 'ErrorPage',
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        message: error.statusText || error.message,
        service: 'Restaurant Default',
        env: 'uat',
        version: '0.0.1',
        organisation: 'TYIMXJyrsby3hAf4Z1Wk',
        storeId: 'fm-generic',
      });
    }
  }, [error]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}